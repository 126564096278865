html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
}

body {
    font-family: 'Amazon Ember', Helvetica, Arial, sans-serif;
    background-color: rgb(234, 237, 237);
}

.awsui {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.app-content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}
