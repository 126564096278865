@import '@amzn/awsui-design-tokens/polaris.scss';

.banner-header {
    padding: 64px 0;
    background-image: url('../assets/images/at2-spr-banner-classroom.png');
    background-size: cover;
    background-position: center;

    h1 {
        font-size: 4rem;
    }
}

.banner-content {
    width: 90%;
    margin: auto;
    max-width: 1100px;
}

.awsui .banner-title {
    color: $color-background-control-default;
    font-weight: 500;
}
