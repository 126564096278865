@import '@amzn/awsui-design-tokens/polaris.scss';

.overflowHiddenText {
    overflow: hidden;
    text-overflow: ellipsis;
}

.class-meta {
    background-color: $color-background-control-default;
    padding: 2rem 0;
    box-shadow: 0 1px 3px 0 rgba(22, 25, 31, 0.2);

    & dd {
        margin-left: 0;
    }

    & dt {
        color: $color-charts-threshold-neutral;
        margin-bottom: 0.75rem;
    }

    & > dl {
        margin: 0;

        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: row;
        }

        & > div * {
            padding-top: 1rem;
            padding-bottom: 1rem;

            &:nth-of-type(1) {
                padding-top: 0;
            }

            &:nth-of-type(n + 2) {
                border-top: 1px solid $color-border-container-top;
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}

.class-meta-cell {
    border-right: 1px solid $color-border-container-top;
    padding: 1rem 3rem !important;
    @media screen and (min-width: 768px) {
        flex-grow: 1;
    }

    &:last-of-type {
        border: none;
    }
}
