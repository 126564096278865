@import '@amzn/awsui-design-tokens/polaris.scss';

.class-header {
    padding: 1.5rem;
    background-color: $color-background-dropdown-item-default;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
